// 
// user.scss
// Use this to write your custom SCSS
//

/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url('/assets/fonts/OpenSans/open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('/assets/fonts/OpenSans/open-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */ 
        url('/assets/fonts/OpenSans/open-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/assets/fonts/OpenSans/open-sans-v17-latin-regular.eot') format('embedded-opentype'), /* IE6-IE9 */
        url('/assets/fonts/OpenSans/open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-600 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
         url('/assets/fonts/OpenSans/open-sans-v17-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/OpenSans/open-sans-v17-latin-600.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/OpenSans/open-sans-v17-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/OpenSans/open-sans-v17-latin-600.eot') format('embedded-opentype'), /* IE6-IE9 */
         url('/assets/fonts/OpenSans/open-sans-v17-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-700 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
         url('/assets/fonts/OpenSans/open-sans-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/OpenSans/open-sans-v17-latin-700.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/OpenSans/open-sans-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/OpenSans/open-sans-v17-latin-700.eot') format('embedded-opentype'), /* IE6-IE9 */
         url('/assets/fonts/OpenSans/open-sans-v17-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* dosis-regular - latin */
  @font-face {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('/assets/fonts/Dosis/dosis-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/Dosis/dosis-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/Dosis/dosis-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/Dosis/dosis-v17-latin-regular.eot') format('embedded-opentype'), /* IE6-IE9 */
         url('/assets/fonts/Dosis/dosis-v17-latin-regular.svg#Dosis') format('svg'); /* Legacy iOS */
  }
  /* dosis-600 - latin */
  @font-face {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
         url('/assets/fonts/Dosis/dosis-v17-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/Dosis/dosis-v17-latin-600.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/Dosis/dosis-v17-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/Dosis/dosis-v17-latin-600.eot') format('embedded-opentype'), /* IE6-IE9 */
         url('/assets/fonts/Dosis/dosis-v17-latin-600.svg#Dosis') format('svg'); /* Legacy iOS */
  }
  /* dosis-700 - latin */
  @font-face {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
         url('/assets/fonts/Dosis/dosis-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/Dosis/dosis-v17-latin-700.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/Dosis/dosis-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/Dosis/dosis-v17-latin-700.eot') format('embedded-opentype'), /* IE6-IE9 */
         url('/assets/fonts/Dosis/dosis-v17-latin-700.svg#Dosis') format('svg'); /* Legacy iOS */
  }

  body{
    font-family: system-ui;
    font-size: 1em;
  }

  p, li {
    font-family: 'Open Sans', sans-serif;
    font-display: swap;
    color:#606060;
  }
  h1,h2,h3,h4,p.h4{
    font-family: 'Dosis', sans-serif;
    font-display: swap;
  }
  .lead{
    color: $primary;
  }
  
  .badge-primary-soft {
    background-color: rgba(97, 183, 184, 0.1);
    color: #61b7b8;
  }
 

  .font-size-lg {font-size: 1.1875rem !important;}
  .font-weight-bold {font-weight: 600 !important; }

  .text-dark {color: #606060 !important; }
  .bg-dark {background-color: #606060 !important; }

  .text-muted{font-weight: 600;}
  .text-muted {color: #8CCACB !important;}
  .bg-dark .text-muted{font-weight: 500}

  .bg-light {background-color: #f2f3f4 !important;}
  .text-light {color: #f2f3f4 !important;}
  
  .bg-white-200 {background-color: #f7ffff !important;}
  .text-white-200 {color: #f7ffff !important;}

  .bg-white-300 {background-color: #fefefa !important;}
  .text-white-300 {color: #fefefa !important;}

  .bg-primary-desat {background-color: #87cfc4 !important;}
  .text-primary-desat {color: #87cfc4 !important;}
  
  .text-gray-200{color: #f2f3f4 !important}
  .bg-gray-200 {background-color: #f2f3f4 !important;}

  .bg-gray-300 {background-color: #f6f4f0 !important;}
  .text-gray-300 {color: #f6f4f0 !important;}

  .bg-gray-400 {background-color: #d3d0cb !important;}
  .text-gray-400 {color: #d3d0cb !important;}

  .btn-primary-desat{color:#fff;background-color: #87cfc4;}
  .btn-primary-soft{color:#61b7b8;}

  .btn-secondary{background-color: #ff7675 !important;}

  .btn-rounded-circle.btn-lg{padding-left: 1.3rem;padding-right: 1.3rem;}
  
  .btn-block + .btn-block {margin-top: 0.5rem;}

  .bg-gradient-light-white {
    background-image: linear-gradient(to bottom, #f2f3f4 0%, #FFFFFF 100%);
  }

  .noBreak{word-break: keep-all;}
  .navbar-light .navbar-nav .nav-link {color: #606060;}
    
  .navbar-brand {margin-top: -14px;}

    
  @supports ((-webkit-backdrop-filter: blur(10px) saturate(100%) contrast(100%) brightness(130%)) or (backdrop-filter: blur(10px) saturate(100%) contrast(100%) brightness(130%))) {
    nav.navbar.bg-white{background-color: transparent!important;}
    nav.navbar.bg-white:before {
      background-color: rgba(255, 255, 255, 0.6)!important;
      /*box-shadow: 0 8px 32px 0 rgba(14, 14, 14, 0.37);*/
      -webkit-backdrop-filter: blur(10px) saturate(100%) contrast(80%) brightness(130%);
      backdrop-filter: blur(10px) saturate(100%) contrast(80%) brightness(130%);
      content: '';
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  .dropdown > a[href='#'],
  .cursor-pointer{cursor: default}
  
  #imgprivatetraining.lazy,
  #imgrealdogyoga.lazy{
    width: 250%;
    height: 250%;
  }
  #imgbannerhome.lazy{
      width: 220%;
      height: 220%; 
  }
  #imgbannerhome.lazy-loaded{
    width: 120%;
    height: 120%;
  }
  #imgrealdogyoga.lazy{
    width: 400%;
    height: 400%;
  }
  #imgrealdogyoga.lazy-loaded,
  #imgprivatetraining.lazy-loaded{
    width: 150%;
    height: 150%;
  }
  
  img.lazy {
    opacity: 0.4;
    transition: opacity .333s ease-in;
  }
  
  img.lazy-loaded {
    opacity: 1;
  } 
  
  #testimonials p{
      font-size:0.8em
  }
  .starrating {
      color: #e5a629 !important;
      font-size: 1.375em;
  }
  
  .listicle .wrap{
      padding-left: calc(1% + 1vw + 1vh) !important;
      padding-right: calc(1% + 1vw + 1vh) !important;
  }

  
  p.weekindic span{
      padding-top: 20px !important;
      display: block !important;
  }
  
  .listicle .wrap p.weekindic{
    margin-left: -10px;
  }
  .listicle .wrap p.weekindic span{
    margin-left: 10px;
  }

  // class overview
  .btnEnrol{
      width: 90%;
      background-color: #f75f5f;
    border:0;
    color: #fff;
  }
  
  .btn-enrol-primary{
      background-color: #f75f5f;
    border:0;
    margin-top: 4px;
    color: #fff;
    text-transform: uppercase;
    min-width: 48%;
  }
  .btn-enrol-primary:hover{
    color: #a20808;
  }

.alert-secondary {
  color: #8f5f66;
  background-color: #ff9c9b;
  border-color: #ff9c9b; }
.alert-secondary hr {
  border-top-color: #ff8382; }
.alert-secondary .alert-link {
  color: #704b50; }
  
  @media (max-width: 507px) {
    .btn-enrol-primary{
      min-width: 100%;
    }
    #stars.starrating{
      font-size: 1.575em;
    }
  }
  
  #puppyschool, #whatwhenwhere, #puppyClassBenefits, #puppyClassOverview,
  #dogtraining, #learnhowto,
  #dogyoga, #learnhowto {
      margin-top:50px;
      margin-bottom:10px;
  }
  .listicle {
      background: #fff;
      /*box-shadow: 1px 0 0 0 rgb(230, 230, 230), 0 1px 0 0 rgb(230, 230, 230), 1px 1px 0 0 rgb(230, 230, 230), 1px 0 0 0 rgb(230, 230, 230) inset, 0 1px 0 0 rgb(230, 230, 230) inset;*/
      padding: 1em;
  
      text-align: left;
      letter-spacing: normal;
      word-spacing: normal;
      text-rendering: auto;
      font-family: FreeSans,Arimo,"Droid Sans",Helvetica,Arial,sans-serif;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
  }
  .column-first{
      padding-top:5px;
      width: 12.5%;
      display: inline-block;
      zoom: 1;
      letter-spacing: normal;
      word-spacing: normal;
      vertical-align: top;
      text-rendering: auto;
      text-align: center 
  }
  .column-last{
      width: 87.5%;
  }
  .listicle .wrap {
      margin: 0 auto;
      max-width: 80em;
      padding: 0 1em;
      position: relative;
  }
  .listicle h3{
      font-weight: 600;
  }
  .listicle ul{
      list-style: none;
      list-style-position: outside;
      padding-left: 1em;
  }
  .listicle li::before {
    content: "•";
    color: #61b7b8;
    font-size: 1.1em;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }
  p.weekindic{
      font-size: 1.6em;
      line-height: 1.4;
      font-weight: 600;
      color: #61b7b8;
      margin-top:-10px;
  }
  p.weekindic span{
      font-size: 3.4em;
      display: block;
      padding-top:10px;
      padding-left:10px;
      font-weight: 900;
      margin-top:-45px;
  }

  .btn-grad {
    background-image: linear-gradient(to right, $primary-bg-lg 0%, $primary-txt-lg 51%, $primary 100%);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
  }

  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .flickity-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $input-height; height: $input-height;
      background-color: $primary;
      box-shadow: $box-shadow-light;
      color: $white;
    
      &::before, &::after {
        font-family: "Font Awesome 5 Pro";
      }
    
    }
    
  .accordion-button{
    font-size: 1.3rem !important;
  }
  
  /* footer */
  #Greview, .sticky{
      position: fixed;
      bottom: 0px;
      left: 5px;
      z-index: 1000000001;
  }
  
  footer .copyright{
      line-height: 1.6em;
      text-align: center;
      font-size: 0.8rem !important;
  }
  footer li{font-size: 0.8em;}
  footer .logomarks div{
      padding-top: 20px;
  }
  footer .logomarks img{
      max-height: 75px;
      max-width: auto;
  }
  footer .copyright p.uparrow{
      padding: 0;
      margin: -2px 2px;
      font-size: 1.4rem;
  }
  footer hr {
      border-top: 1px solid #000; }
  abbr.cpdt{
      font-size: 70%;
      font-variant: none;
      text-decoration: none;
      cursor: help;
      border-bottom: 0px;
  }
  ul.checklistcallout{
      border: 0px solid #000;
      padding:1px;
      list-style: none;
      margin-bottom: 5px;
  }
  ul.checklistcallout li{
      display: block;
      line-height: 1.8em;
      font-size: 0.8em;
  }
  footer li i{
      color:#61b7b8;
  }
  ul.checklistcallout span{
      margin-right: 3px;
  }
  footer{
      font-size: 1.2em;
      line-height: 1.6em;
  }
  
  
  @media(max-width:507px) {
      p.weekindic {
          font-size: calc(140% + 1vw + 1vh) !important;
          font-weight: 700;
      }
      p.weekindic span{
          padding: 0 5px;
          display: inline !important;
          font-size: 1em;
      }
      .column-first, .column-last{
          width: 100%;
      }
      .btn-sm-sm{
          padding: 0.5625rem 1rem;
          font-size: 1.0625rem;
          line-height: 1.3;
          width: 100%;
          margin-bottom: 2px;
          display: block;
      }
  }
  
  body.gbt footer div.col-12.col-sm-6.col-md-3{
    line-height: 2em;
    flex: 0 0 100%;
    max-width: 100%;
  }
  body.gbt footer li.list-inline-item.list-social-item>a{
    padding: 40px;
  }
  
  @media (min-width: 768px) {
    .position-md-static {
      position: static !important; }
    .position-md-relative {
      position: relative !important; }
    .position-md-absolute {
      position: absolute !important; }
    .position-md-fixed {
      position: fixed !important; }
    .position-md-sticky {
      position: -webkit-sticky !important;
      position: sticky !important; } }
      
  
  .darkmode{display:none}
  @media (prefers-color-scheme: light) {
  
  }
  
  @media (prefers-color-scheme: darker) {
  
      body {
          background-color: rgb(30,34,39)!important;
          color: #fff;
      }
      .darkmode{display:block;}
      .navbar-brand-img{
          filter:grayscale(100%)
      }
        .bg-white,
        .bg-light,
        .btn-white,
        .bg-white-300,
        .bg-gray-100,
        .bg-gray-200,
        .bg-gray-300,
        .listicle       {
          background-color: rgb(30,34,39)!important;
          color: #f7ffff;
        }
        .text-white,
        .text-white-200,
        .text-white-300,
        .text-gray-100,
        .text-gray-200,
        .text-gray-300,
        .text-light {
          color: rgb(30,34,39)!important;
        }
        .bg-white-200,
        #info{
          background-color: rgb(49, 51, 53)!important;
          color: #f7ffff;
        }
        #puppyClassBenefits.text-white,
        #puppyClassBenefits .text-white,
        .navbar-nav .nav-link{
          color:#f7ffff !important;
        }
        #navbarCollapse.show{
          background-color: rgb(55, 56, 58)!important;
        }
        #navbarCollapse .text-gray-900,
        #navbarCollapse .list-link{
          color:#f7ffff !important;
        }
        .text-gray-200,
        .text-light,
        #navbarCollapse {
          color: rgb(55, 56, 58)!important;
        }
        .bg-gradient-light,
        .bg-gradient-light-white,
        .card-body{
            background-image:linear-gradient(to bottom, #333 0%,rgb(30,34,39) 100%)
        }
        .svg-shim.text-light,.svg-shim.text-gray-200{display: none}
        .dropdown-menu {
          background-color: rgb(55, 56, 58)!important;
          color: #606060;
        }
        .fancybox-slide{
          background-color: rgb(30,34,39)!important;
        }
        
  }
  
  
  @media (prefers-contrast: high) {
    a, 
    .text-primary,
    .text-muted{
      color: #2E8283;
    }
    .btn-primary-desat{
      background-color: #2E8283;
    }
  }
  
  
  /* portrait */
  @media screen and (orientation:portrait) {
    /* portrait-specific styles */
    .d-none-portrait { 
      display:none !important;
    }
    .d-block-portrait { 
      display:block !important;
    }
    .d-inline-portrait { 
      display:inline !important;
    }
  }
  /* landscape */
  @media screen and (orientation:landscape) {
     /* landscape-specific styles */
    .d-none-landscape { 
       display:none !important;
    }
    .d-block-landscape { 
      display:block !important;
    }
    .d-inline-landscape { 
      display:inline !important;
    }
  }


.cookieinfo{
  box-shadow: 0px -2px 10px #606060;

  background: linear-gradient(-45deg, #23D5AB, #23A6D5, #EE7752, #E73C7E );
  /*background: linear-gradient(-45deg, #0F8A5F, #34A65F, #F5624D, #CC231E ); XMAS*/
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}
#bookings .cookieinfo{
  display: none !important;
}

/* added margin for anchor linking */
#Health_Safety_Hygiene,
#Bookings_Cancellations_Refunds{
padding-top: 120px;
}


@-webkit-keyframes Gradient {
0% {
    background-position: 0% 50%
}
50% {
    background-position: 100% 50%
}
100% {
    background-position: 0% 50%
}
}

@-moz-keyframes Gradient {
0% {
    background-position: 0% 50%
}
50% {
    background-position: 100% 50%
}
100% {
    background-position: 0% 50%
}
}

@keyframes Gradient {
0% {
    background-position: 0% 50%
}
50% {
    background-position: 100% 50%
}
100% {
    background-position: 0% 50%
}
}

#ctaHomePage{
    display:flex;
    flex-wrap: wrap;
}
#ctaHomePage a{
    flex: 1 1;
    min-width: 10em;
    width:100%;
    margin: 10px;
}
#ctaHomePage button{
    width:100%;
}

#looseLeashWalking p>em{
  padding-left:1em;
}

@media (prefers-color-scheme: darker) {

.cookieinfo{
    background: linear-gradient(-45deg, #999999, #333, #303030, #606060 );
    color: aliceblue !important;
    background-size: 400% 400%;
    -webkit-animation: Gradient 15s ease infinite;
    -moz-animation: Gradient 15s ease infinite;
    animation: Gradient 15s ease infinite;
}
.cookieinfo a{
    color: aliceblue !important;
}
body#termsConditions .text-gray-900,
#privacyPolicy .text-gray-900,
#dataProtection .text-gray-900{
    color:#fff !important;
}
}